import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@chakra-ui/react";

import { ChatState } from "../Context/ChatProvider";
import SideDrawer from "../Components/miscellaneous/SideDrawer";
import MyChats from "../Components/Chat/MyChats";
import ChatBox from "../Components/Chat/ChatBox";

const ChatPage = () => {
  // hooks --------------------------------------------------------------------------
  const { user } = ChatState(); // ! get app-wide context: user logged in state
  const [fetchAgain, setFetchAgain] = useState(false);

  // handlers ----------------------------------------------------------------------------

  // FIXME: after signup and push to '/chats', no data is fetched


  // jsx ----------------------------------------------------------------------------
  const topToolBar = user && <SideDrawer />;
  const myChatList = user && <MyChats fetchAgain={fetchAgain} />;
  const myChatBox = user && (
    <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
  );

  return (
    <div style={{ width: "100%" }}>
      {topToolBar}

      <Box
        display="flex"
        justifyContent="space-between"
        w="100%"
        h="91.5vh"
        padding="10px"
      >
        {myChatList}
        {myChatBox}
      </Box>
    </div>
  );
};

export default ChatPage;
