import React, { useEffect, useState } from "react";
import { ChatState } from "../../Context/ChatProvider";
import { Box, Button, Stack, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { AddIcon } from "@chakra-ui/icons";
import ChatLoading from "./ChatLoading";

import { getSender } from "../config/ChatLogics";
import GroupChatModal from "../miscellaneous/GroupChatModal";
import { ENDPOINT } from "../../env";

const MyChats = ({ fetchAgain }) => {
  const [loggedUser, setLoggedUser] = useState();
  const { user, selectedChat, setSelectedChat, chats, setChats } = ChatState();

  const toast = useToast();

  // fetch all chats of the logged in user
  const fetchChats = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.get(`${ENDPOINT}/api/chat`, config); // ! axios get
      console.log(data);
      setChats(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to Load the Seach Results",
        status: "error",
        duration: 5000,
        isCLosable: true,
        position: "bottom-left",
      });
    }
  };

  // ========================================================================== //
  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats(); // !
  }, [fetchAgain]);

  // jsx ----------------------------------------------------------------------------
  const myChatsHeader = (
    <Box
      pb={3}
      px={3}
      fontSize={{ base: "28px", md: "30px" }}
      fontFamily="Work sans"
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      My Chats
      <GroupChatModal>
        <Button
          display="flex"
          fontSize={{ base: "17px", md: "10px", lg: "17px" }}
          rightIcon={<AddIcon />}
        >
          New Group Chat
        </Button>
      </GroupChatModal>
    </Box>
  );
  const renderMyChatListItem = (chat) => {
    return (
      <Box
        onClick={() => setSelectedChat(chat)}
        cursor="pointer"
        bg={selectedChat === chat ? "#38B2Ac" : "#E8E8E8"} // selected visual feedback
        color={selectedChat === chat ? "white" : "black"}
        px={3}
        py={2}
        borderRadius="lg"
        key={chat._id}
      >
        {/* title of a chat box */}
        <Text>
          {!chat.isGroupChat
            ? getSender(loggedUser, chat.users)
            : chat.chatName}
        </Text>
      </Box>
    );
  };
  const myChatLists = (
    <Box
      display="flex"
      flexDirection="column"
      p={3}
      bg="#F8F8F8"
      w="100%"
      h="100%"
      borderRadius="lg"
      overflow="hidden"
    >
      {chats ? (
        <Stack overflowY="scroll">
          {chats.map((chat) => renderMyChatListItem(chat))}
        </Stack>
      ) : (
        <ChatLoading />
      )}
    </Box>
  );

  return (
    <Box
      display={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDirection="column"
      alignItems="center"
      p={3}
      bg="white"
      w={{ base: "100%", md: "31%" }}
      borderRadius="1g"
      borderWidth="1px"
    >
      {myChatsHeader}

      {myChatLists}
    </Box>
  );
};

export default MyChats;
