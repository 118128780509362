import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  FormControl,
  Input,
  Box,
  Text,
} from "@chakra-ui/react";
import { ChatState } from "../../Context/ChatProvider";
import axios from "axios";
import UserListItem from "../UserAvatar/UserListItem";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import { ENDPOINT } from "../../env";

const GroupChatModal = ({ children }) => {
  // hooks --------------------------------------------------------------
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [groupChatName, setGroupChatName] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]); // selected users to add into group chat
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]); // searched user list
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const { user, chats, setChats } = ChatState();

  // handlers -------------------------------------------------------------
  const searchHandler = async (query) => {
    setSearch(query);
    if (!query) {
      return;
    }

    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.get(
        `${ENDPOINT}/api/user/?search=${search}`,
        config
      ); // ! axios get
      console.log(data);

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to load the search results",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  // ========================================================================== //
  const submitHandler = async () => {
    if (!groupChatName || !selectedUsers) {
      toast({
        title: "Please fill all the field!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(
        // ! axios post
        `${ENDPOINT}/api/chat/group`,
        {
          name: groupChatName,
          users: JSON.stringify(selectedUsers.map((u) => u._id)),
        },
        config
      );

      setChats([data, ...chats]);
      onClose(); // close the modal
      toast({
        title: "New Group Chat Created!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      toast({
        title: "Failed to Create the Chat!",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  // ========================================================================== //
  // add a user to selectedUsers
  const handleGroup = (userToAdd) => {
    console.log(`adding user ${userToAdd.name} to selectedUsers`);

    if (selectedUsers.includes(userToAdd)) {
      toast({
        title: "User Already Added!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setSelectedUsers([...selectedUsers, userToAdd]);
    console.log(`already added user ${userToAdd.name} to selectedUsers`);
  };

  // ========================================================================== /
  const handleDelete = (userToDelete) => {
    setSelectedUsers(
      selectedUsers.filter((sel) => sel._id !== userToDelete._id) // keep those
    );
  };

  // jsx -------------------------------------------------------------------------

  const selectedUsersList = (
    <Box width="100%" display="flex" flexWrap="wrap">
      {selectedUsers.map((user) => (
        <UserBadgeItem
          key={user._id}
          user={user}
          handleFunction={() => handleDelete(user)}
        />
      ))}
    </Box>
  );
  const searchedUserList = (
    <Box width="100%">
      <Text>Searched Users:</Text>
      {loading ? (
        <div>laoding</div>
      ) : (
        searchResult?.slice(0, 4).map((user) => (
          <UserListItem
            key={user._id}
            user={user}
            handleFunction={() => handleGroup(user)} // onClick: add user to selectedUsers
          />
        ))
      )}
    </Box>
  );

  const createGroupChatModal = (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader
          fontSize="35px"
          fontFamily="Work sans"
          display="flex"
          justifyContent="center"
        >
          Create Group Chat
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <FormControl>
            <Input
              placeholder="Chat Name"
              mb={3}
              onChange={(e) => {
                setGroupChatName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Add User eg: John, Bob ..."
              mb={1}
              onChange={(e) => {
                searchHandler(e.target.value);
              }}
            />
          </FormControl>

          {selectedUsersList}
          {searchedUserList}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={submitHandler}>
            Create Chat
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <>
      <span onClick={onOpen}>{children}</span>

      {createGroupChatModal}
    </>
  );
};

export default GroupChatModal;
