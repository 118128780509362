import {
  Box,
  Button,
  Menu,
  MenuButton,
  Text,
  Tooltip,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BellIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { ChatState } from "../../Context/ChatProvider";
import ProfileModal from "./ProfileModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ChatLoading from "../Chat/ChatLoading";
import UserListItem from "../UserAvatar/UserListItem";
import { getSender } from "../config/ChatLogics";
import { ENDPOINT } from "../../env";

const SideDrawer = () => {
  const [search, setSearch] = useState(""); // input
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false); // loading search results
  const [loadingChat, setLoadingChat] = useState(); //loading chat results

  const {
    user,
    setSelectedChat,
    chats,
    setChats,
    notifications,
    setNotifications,
  } = ChatState(); // get app-wide context: user's logged in state
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure(); // Drawer
  const toast = useToast();

  // handlers -------------------------------------------
  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    history.push("/");
  };

  // ! search all users that matches input
  const searchHandler = async () => {
    if (!search) {
      toast({
        title: "Please Enter something in search",
        status: "warning",
        duration: 5000,
        isCLosable: true,
        position: "top-left",
      });
      return;
    }

    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.get(
        `${ENDPOINT}/api/user?search=${search}`,
        config
      ); // ! axios get: an array of users matching input

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to Load the Seach Results",
        status: "error",
        duration: 5000,
        isCLosable: true,
        position: "bottom-left",
      });
    }
  };

  //! access chat with specific user
  const accessChat = async (userId) => {
    try {
      setLoadingChat(true);

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(
        `${ENDPOINT}/api/chat`,
        { userId },
        config
      ); // ! axios post, create a chat with a specified user

      // if newly created chat not in chats, append it to chats
      if (!chats.find((c) => c._id === data._id)) {
        setChats([data, ...chats]);
      }

      setSelectedChat(data);
      setLoadingChat(false);
      onClose();
    } catch (error) {
      toast({
        title: "Error fetching the chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  // jsx --------------------------------------------------------------------
  const searchUser = (
    <Tooltip label="Search User to Chat" hasArrow placement="bottom-end">
      <Button variant="ghost" onClick={onOpen}>
        <i class="fas fa-search"></i>
        <Text display={{ base: "none", md: "flex" }} px="4">
          Search User
        </Text>
      </Button>
    </Tooltip>
  );

  const notificationMenu = (
    <Menu>
      <MenuButton p={1}>
        {/* notification badge here (outdated dependency) */}
        <BellIcon
          fontSize="2xl"
          color={notifications.length > 0 ? "red" : "black"}
          m={1}
        />
      </MenuButton>
      <MenuList pl={2}>
        {!notifications.length && "No New Messages"}
        {notifications.map((notif) => (
          <MenuItem
            key={notif._id}
            onClick={() => {
              setSelectedChat(notif.chat);
              setNotifications(notifications.filter((n) => n !== notif));
            }}
          >
            {notif.chat.isGroupChat
              ? `New Message in ${notif.chat.chatName}`
              : `New Message from ${getSender(user, notif.chat.users)}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  const userProfileMenu = (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        <Avatar size="sm" cursor="pointer" name={user.name} src={user.pic} />
      </MenuButton>

      <MenuList>
        <ProfileModal user={user}>
          <MenuItem>My Profile</MenuItem>
        </ProfileModal>

        <MenuDivider />
        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );

  // the drawer used to display search other user to chat
  const searchUserInput = (
    <Box display="flex" pb={2}>
      <Input
        placeholder="Search by name or email"
        mr={2}
        value={search} // 2 way binding
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button onClick={searchHandler}>Go</Button>
    </Box>
  );

  const searchUserResult = loading ? (
    <ChatLoading />
  ) : (
    searchResult.map((user) => {
      return (
        <UserListItem
          key={user._id}
          user={user}
          handleFunction={() => accessChat(user._id)}
        />
      );
    })
  );

  const searchUserSideDrawer = (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Search Users</DrawerHeader>

        <DrawerBody>
          {searchUserInput}
          {searchUserResult}

          {/* feedback of loading chat */}
          {loadingChat && <Spinner ml="auto" display="flex" />}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        w="100%"
        p="5px 10px 5px 10px"
        borderWidth="5px"
      >
        {searchUser}

        <Text fontSize="2xl" fontFamily="Work sans">
          Talk-A-Tive
        </Text>

        <div>
          {notificationMenu}
          {userProfileMenu}
        </div>
      </Box>

      {searchUserSideDrawer}
    </>
  );
};

export default SideDrawer;
