import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useToast,
  Button,
} from "@chakra-ui/react";
import Login from "../Components/Auth/Login";
import Signup from "../Components/Auth/Signup";
import axios from "axios";
import { ENDPOINT } from "../env";

const HomePage = () => {
  // hooks -------------------------------------------------------------
  const history = useHistory(); // react router 5 hook
  const toast = useToast();

  useEffect(() => {
    // check local storage, keep user login state
    const user = JSON.parse(localStorage.getItem("userInfo"));

    console.log("in home page, geting user logged in state");

    if (user) {
      console.log(`${user.name} has logged in, now directly to chat page`);
      history.push("/chats");
    }
  }, [history]);

  // handlers ----------------------------------------------------------
  const handleHealthCheck = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${ENDPOINT}/healthcheck`, config);

      console.log(data);

      // ! ========================================================================== //
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: `Failed to health check the server ${
          error instanceof Error && error.message
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  // jsx---------------------------------------------------------------
  const talkativeTitle = (
    <Box
      d="flex"
      justifyContent="center"
      p={3}
      bg={"white"}
      w="100%"
      m="40px 0 15px 0"
      borderRadius="lg"
      borderWidth="1px"
    >
      <Text
        fontSize="4xl"
        fontFamily="Work sans"
        color="black"
        textAlign="center"
      >
        Talk-A-Tive
      </Text>
    </Box>
  );

  const signinSignupTabs = (
    <Box
      bg="white"
      w="100%"
      p={4}
      borderRadius="lg"
      borderWidth="1px"
      color="black"
    >
      <Tabs variant="soft-rounded">
        <TabList mb="1em">
          <Tab width="50%">Login</Tab>
          <Tab width="50%">Sign Up</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Login />
          </TabPanel>
          <TabPanel>
            <Signup />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );

  return (
    <Container maxW="xl" centerContent>
      {talkativeTitle}
      {signinSignupTabs}

      {/* <Box
        bg="white"
        w="100%"
        p={4}
        borderRadius="lg"
        borderWidth="1px"
        color="black"
      >
        <Button
          variant="solid"
          colorScheme="red"
          width="100%"
          style={{ marginTop: 15 }}
          onClick={() => {
            handleHealthCheck();
          }}
        >
          Health Check Server
        </Button>
      </Box> */}
    </Container>
  );
};

export default HomePage;
