import { ViewIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Button,
  useToast,
  Box,
  FormControl,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { ChatState } from "../../Context/ChatProvider";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import axios from "axios";
import UserListItem from "../UserAvatar/UserListItem";
import { ENDPOINT } from "../../env";

const UpdateGroupChatModal = ({ fetchAgain, setFetchAgain, fetchMessages }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [groupChatName, setGroupChatName] = useState();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false); // loading boolean feedback for rename group chat api

  const { selectedChat, setSelectedChat, user } = ChatState();

  const toast = useToast();

  // ========================================================================== //
  const handleRenameGroupChat = async () => {
    if (!groupChatName) return;

    try {
      setRenameLoading(true); // visual feedback

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.put(
        // ! axios put
        `${ENDPOINT}/api/chat/rename`,
        {
          chatId: selectedChat._id,
          chatName: groupChatName,
        },
        config
      );
      setSelectedChat(data); // ! update ui state with updated group chat
      setFetchAgain(!fetchAgain);

      setRenameLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error.message,
        status: "error",
        duration: 5000,
        isCLosable: true,
        position: "bottom",
      });
      setRenameLoading(false);
    }
    setGroupChatName("");
  };

  // ========================================================================== //
  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      return;
    }

    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.get(
        `${ENDPOINT}/api/user/?search=${search}`,
        config
      ); // ! axios get
      console.log(data);

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to load the search results",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  // ========================================================================== //
  const handleAddUserToGroupChat = async (userToAdd) => {
    if (selectedChat.users.find((u) => u._id === userToAdd._id)) {
      toast({
        title: "User Already in group!",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (selectedChat.groupAdmin._id !== user._id) {
      // ! access control
      toast({
        title: "Only admins can add someone!",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.put(
        //! axios put
        `${ENDPOINT}/api/chat/groupadd`,
        {
          chatId: selectedChat._id,
          userId: userToAdd._id,
        },
        config
      );
      setSelectedChat(data); // ! update UI state
      setFetchAgain(!fetchAgain);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  // ========================================================================== //
  const handleRemoveUserFromGroupChat = async (userToRemove) => {
    if (
      selectedChat.groupAdmin._id !== user._id &&
      userToRemove._id !== user.id
    ) {
      toast({
        title: "only admins can remove someone!",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.put(
        // ! axois put
        `${ENDPOINT}/api/chat/groupremove`,
        {
          chatId: selectedChat._id,
          userId: userToRemove._id,
        },
        config
      );

      userToRemove._id === user._id ? setSelectedChat() : setSelectedChat(data); // ! update UI state
      setFetchAgain(!fetchAgain); // fetch all chats again
      fetchMessages(); // ! fetch message of current selected chat again
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  // jsx --------------------------------------------------------------

  // modal body  ---------
  const groupChatUserList = (
    <Box width="100%" display="flex" flexWrap="wrap" pb={3}>
      {selectedChat.users.map((u) => (
        <UserBadgeItem
          key={u.id}
          user={u}
          handleFunction={() => handleRemoveUserFromGroupChat(u)}
        />
      ))}
    </Box>
  );
  const updateGroupChatNameForm = (
    <FormControl display="flex">
      <Input
        placeholder="Chat Name"
        mb={3}
        value={groupChatName}
        onChange={(e) => setGroupChatName(e.target.value)}
      />
      <Button
        variant="solid"
        background="teal"
        color="white"
        ml={1}
        isLoading={renameLoading}
        onClick={handleRenameGroupChat}
      >
        Update
      </Button>
    </FormControl>
  );
  const addNewUserToGroupChatInput = (
    <FormControl>
      <Input
        placeholder="Add User to Group"
        mb={1}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </FormControl>
  );
  const newUserSearchResults = loading ? (
    <Spinner size="lg" />
  ) : (
    searchResult?.map((user) => (
      <UserListItem
        key={user._id}
        user={user}
        handleFunction={() => handleAddUserToGroupChat(user)}
      />
    ))
  );
  const modalToDisplay = (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader
          fontSize="35px"
          fontFamily="Work sans"
          display="flex"
          justifyContent="center"
        >
          {selectedChat.chatName}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {groupChatUserList}

          {updateGroupChatNameForm}

          {addNewUserToGroupChatInput}
          {newUserSearchResults}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            onClick={() => handleRemoveUserFromGroupChat(user)}
          >
            Leave Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <>
      <IconButton
        display={{ base: "flex" }}
        icon={<ViewIcon />}
        onClick={onOpen}
      />

      {modalToDisplay}
    </>
  );
};

export default UpdateGroupChatModal;
